import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  languageCode: string
  membership?: boolean
  place?: string
  title?: string
  website?: string
}

export const Hotel = memo(function Hotel({
  image,
  languageCode,
  membership,
  place,
  title,
  website,
}: Props) {
  return (
    <Container
      href={website ? website : undefined}
      rel="noopener"
      target="_blank"
    >
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      {membership ? (
        <Membership className="hotel-membership">
          {useVocabularyData('member-of', languageCode)}
          <Radisson alt="Radisson" src="/radisson.png" width="86" height="25" />
        </Membership>
      ) : null}

      <Wrapper className="hotel-wrapper">
        {place ? <Place className="hotel-place">{place}</Place> : null}
        {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
      </Wrapper>

      {website ? (
        <Website className="hotel-website">
          {useVocabularyData('visit-website', languageCode)}
        </Website>
      ) : null}
    </Container>
  )
})

const Container = styled.a`
  width: calc(50% - 1.5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 1.5rem 1.5rem 0 0;
  overflow: hidden;
  padding-bottom: 39.5%;
  position: relative;
  &:nth-of-type(even) {
    transform: translateY(3.75rem);
  }
  &:hover {
    picture {
      img {
        transform: scale(1.1);
      }
    }
    .hotel-membership {
      opacity: 0;

      @media (max-width: 1023px) {
        opacity: 1;
      }
    }

    @media (min-width: 1024px) {
      .hotel-wrapper {
        bottom: 6.8125rem;
      }
    }

    .hotel-place {
      opacity: 0;

      @media (max-width: 1023px) {
        opacity: 1;
      }
    }
    .hotel-website {
      opacity: 1;
      &:after {
        right: 0;
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s ease-in-out;
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0.0625rem 0;
    padding-bottom: 50%;

    &:nth-of-type(even) {
      transform: translateY(0);
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 100%;
  }
`

const Membership = styled.div`
  background-color: ${rgba(theme.colors.variants.neutralLight4, 0.05)};
  backdrop-filter: blur(15px) contrast(0.9);
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.075rem;
  line-height: 2rem;
  padding: 1.5rem 1.6875rem 1.875rem;
  position: absolute;
  top: 0;
  right: 1.875rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 4;

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: ${rgba(theme.colors.variants.neutralLight4, 0.25)};
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: ${rgba(theme.colors.variants.neutralLight4, 0.25)};
  }

  @media (max-width: 767px) {
    right: 1.25rem;
  }
`

const Radisson = styled.img`
  display: block;
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 3.3125rem;
  right: 3.75rem;
  left: 3.75rem;
  transition: 0.3s ease-in-out;
  z-index: 2;

  @media (max-width: 1023px) {
    bottom: 2.5rem;
    right: 1.875rem;
    left: 1.875rem;
  }
`

const Place = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
  transition: 0.3s ease-in-out;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.625rem;
  text-transform: uppercase;
`

const Website = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0875rem;
  line-height: 2rem;
  opacity: 0;
  position: absolute;
  bottom: 3.3125rem;
  left: 3.75rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 2;
  &:after {
    content: '';
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    height: 0.125rem;
    position: absolute;
    bottom: -0.125rem;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    display: none;
    opacity: 1;
    left: 1.875rem;
    &:after {
      right: 0;
    }
  }
`
