import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Hotel, Props as HotelProps } from './Hotel'

export interface Props {
  description?: string
  hotels: HotelProps[]
  title?: string
}

export const Hotels = memo(function Hotels({
  description,
  hotels,
  title,
}: Props) {
  if (!hotels) {
    return null
  }
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      <List row wrap>
        {hotels.map((item, index) => (
          <Hotel key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 5.25rem 0 12.5rem;
  padding: 0 11.944vw;

  @media (max-width: 1023px) {
    padding: 0;
    margin: 5rem 0 9.375rem;
  }

  @media (max-width: 767px) {
    margin: 5rem 0 6.25rem;
  }
`

const Title = styled.h2`
  max-width: 33.125rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;
  margin: auto;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Description = styled.div`
  max-width: 44.0625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin: 1.875rem auto 4.5rem;
  position: relative;
  text-align: center;
`

const List = styled(FlexBox)`
  margin-right: -1.5rem;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`
